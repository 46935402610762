
.table-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
    border: none; 
    border-collapse: collapse;
  }
  
  .table-flex tr {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    border: none; 
  }
  
  .table-flex td,
  .table-flex th {
    flex: 1;
    padding: 13px;
    text-align: left;
    min-width: 150px;
    border: none; 
  }
  
  .table-flex img,
  .image-responsive {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2%; 
  }
  
  
  .table-flex img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  
  @media (max-width: 768px) {
    .table-flex {
      display: flex;
      flex-direction: column; /* Forcer l'affichage en colonne */
    }
  
    .table-flex tr {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  
    .table-flex td {
      width: 100%; /* Chaque élément prend toute la largeur */
    }
  
    /* Assurer alternance paragraphe → image */
    .table-flex td img {
      display: block;
      margin-top: 10px;
      order: 1; /* Image après paragraphe */
    }
  
    .table-flex td p {
      display: block;
      margin-bottom: 10px;
      order: 0; /* Paragraphe avant image */
    }
  
    /* Ajouter des marges pour un meilleur espacement */
    .table-flex tr + tr {
      margin-top: 20px;
    }
  }
  